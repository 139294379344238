import React from "react"
import "./utilStyle.css"
import { Maybe } from "../../../types/graphql-types"

type Props = {
  title?: Maybe<string> | undefined
}

const PageTitle: React.FC<Props> = props => {
  return (
    <div className={"page-title-wrapper"}>
      <div className={"util-page-title"}>{props.title}</div>
      <div className={"hr-wrapper"}>
        <hr className={"hr-style"} />
      </div>
    </div>
  )
}
export default PageTitle
