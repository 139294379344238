import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import "./utilStyle.css"
import HeaderMemu from "./HeaderMenu"
import ChangeLanguageButton from "./ChangeLanguageButton"
import { useLocation } from "@reach/router"
import logoSvg from "../../assets/logo.svg"
type Props = {
  headerMenu: string[]
  selectPageName: string
}

const Header: React.FC<Props> = props => {
  const [isMobile, setIsMobile] = useState(false)
  const [isCalculating, setIsCalculating] = useState(true) // 計算中かどうかの状態
  const location = useLocation()
  const pathName = location.pathname

  const handleResize = () => {
    setIsCalculating(true)
    setIsMobile(window.innerWidth < 768)
    setIsCalculating(false)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    handleResize() // 初回レンダリング時にも幅を計算
    return () => window.removeEventListener("resize", handleResize)
  }, [])
  if (isCalculating) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          height: "80px"
        }}
      >
        <ChangeLanguageButton pathName={pathName} />
      </div>
    )
  }

  return (
    <>
      {isMobile ? (
        <MobileHeader
          headerMenu={props.headerMenu}
          selectPage={props.selectPageName}
          pathName={pathName}
        />
      ) : (
        <DesktopHeader
          headerMenu={props.headerMenu}
          selectPage={props.selectPageName}
          pathName={pathName}
        />
      )}
    </>
  )
}

export default Header

const MobileHeader: React.FC<{
  headerMenu: string[]
  selectPage: string
  pathName: string
}> = ({ headerMenu, selectPage, pathName }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      }}
    >
      <HeaderMemu
        headerMenu={headerMenu}
        selectPageName={selectPage}
        pathName={pathName}
      />
      <Link to="/">
        <div className="header-logo">
          <img src={logoSvg} alt="logo" />
        </div>
      </Link>
      <ChangeLanguageButton pathName={pathName} />
    </div>
  )
}

const DesktopHeader: React.FC<{
  headerMenu: string[]
  selectPage: string
  pathName: string
}> = ({ headerMenu, selectPage, pathName }) => {
  return (
    <div className={"header-wrapper"}>
      <div className={"header-menu-group"}>
        <div className={"desktop-hidden"} style={{ marginRight: "12px" }}>
          <HeaderMemu
            headerMenu={headerMenu}
            selectPageName={selectPage}
            pathName={pathName}
          />
        </div>
        <div>
          <Link to="/">
            <div className="header-logo">
              <img src={logoSvg} alt="logo" />
            </div>
          </Link>
        </div>
      </div>
      <div className={"header-menu-group"}>
        <div className={"mobile-hidden"}>
          <HeaderMemu
            headerMenu={headerMenu}
            selectPageName={selectPage}
            pathName={pathName}
          />
        </div>
        <ChangeLanguageButton pathName={pathName} />
      </div>
    </div>
  )
}
